import React, { useEffect, useState } from "react"
import filterIcon from "@/public/icons/filter.svg"
import carret from "@/public/icons/arrow-up.svg"
import gridOnIcon from "@/public/icons/grid-on.svg"
import useWindowResize from "@/hooks/useWindowResize"
import { ROW_COLUMN_MIN_WIDTH } from "@/constants/index"

const PLPLoader = props => {
  const [width] = useWindowResize()
  const [rowCols, setRowCols] = useState(0)
  useEffect(() => {
    setRowCols(width < ROW_COLUMN_MIN_WIDTH ? 2 : 3)
  }, [])
  return (
    <>
      <div className="product-listing">
        <div className="product-listing__sticky-nav-wrap">
          <div className=" ">
            <div className="container-fluid product-listing__sticky-nav false">
              <div className="product-listing__row">
                <div className="container kf-react-plp-container">
                  <div className="product-listing__row">
                    <div className="product-listing__col product-listing__controls">
                      <h2 className="product-listing__total shimmer-loading__para30 shimmer width-240"></h2>
                      <section className="sorting-section">
                        <span className="product-listing__fillter_sort">
                          Filter &amp; Sort
                        </span>
                        <button
                          id="showFilter"
                          aria-label="Show Filters"
                          className="product-listing__filter-toggle gbh-data-layer"
                        >
                          <ul
                            className={`product-listing__filter-toggle-text  product-listing__filter-toggle-text--show hideMobile
                            }`}
                          >
                            <li className="show">Hide Filters</li>
                            <li className="hide">Hide Filters</li>
                          </ul>

                          <img
                            src={filterIcon.src}
                            className="product-listing__filter-toggle-icon"
                            alt="Toggle Filter"
                          />
                        </button>
                        <div className="product-listing__grid-control">
                          <div className="d-block d-sm-none">
                            <img
                              className="product-listing__grid-control-icon"
                              src={gridOnIcon.src}
                              alt="grid on"
                            />
                          </div>
                        </div>
                        <div className="sort">
                          <button
                            className="sort__toggle"
                            tabIndex="0"
                            aria-label="Featured selected"
                          >
                            Sort By: Featured
                            <img
                              src={carret.src}
                              alt="Sort"
                              className="sort__toggle-icon"
                              aria-hidden="true"
                            />
                          </button>
                          <ul
                            className="sort__drop-down"
                            aria-label="Sort By Button Closed"
                            aria-hidden="true"
                          >
                            <li
                              id="select-options-0"
                              role="option"
                              tabIndex="-1"
                              aria-selected="true"
                              className="sort__item gbh-data-layer active"
                            >
                              Featured
                            </li>
                            <li
                              id="select-options-1"
                              role="option"
                              tabIndex="-1"
                              aria-selected="false"
                              className="sort__item gbh-data-layer "
                            >
                              Price: High - Low
                            </li>
                            <li
                              id="select-options-2"
                              role="option"
                              tabIndex="-1"
                              aria-selected="false"
                              className="sort__item gbh-data-layer "
                            >
                              Price: Low - High
                            </li>
                            <li
                              id="select-options-3"
                              role="option"
                              tabIndex="-1"
                              aria-selected="false"
                              className="sort__item gbh-data-layer "
                            >
                              A - Z
                            </li>
                            <li
                              id="select-options-4"
                              role="option"
                              tabIndex="-1"
                              aria-selected="false"
                              className="sort__item gbh-data-layer "
                            >
                              Z - A
                            </li>
                          </ul>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="product-listing kf-react-plp-container product-listing__tiles">
        <div className="product-listing__row">
          <div className="col product-listing__tile-col product-listing__tile-col--full-width">
            <div>
              <div className="product-listing__filter borderNone">
                <div>
                  <div>
                    <div className="filter__inner-container shimmer height-300"></div>
                    <div className="filter__footer">
                      <div className="products-per-page width-240 mt60 mb20">
                        <p className="shimmer-loading__headpara shimmer"></p>
                      </div>
                      <div className="products-per-page width-100">
                        <p className="shimmer-loading__para shimmer"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="product-listing__tile-col">
                <div className={`product-listing__row row-cols-${rowCols}`}>
                  <div className=" product-listing__tile product-listing__col px-1  ">
                    <div className="lazyload-wrapper ">
                      <div className="product-tile">
                        <div className="product-list-tile__image wow ">
                          <div className="shimmer-loading__image shimmer"></div>
                        </div>
                        <div className="product-tile__details">
                          <div className="width-full">
                            <p className="shimmer-loading__para shimmer width-170 mt60"></p>
                            <p className="shimmer-loading__headpara shimmer  mb20 width-full"></p>
                            <p className="shimmer-loading__para shimmer width-100"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="product-listing__col px-1 product-listing__tile">
                    <div className="lazyload-wrapper ">
                      <div className="product-tile">
                        <div className="product-list-tile__image wow ">
                          <div className="shimmer-loading__image shimmer"></div>
                        </div>
                        <div className="product-tile__details">
                          <div className="width-full">
                            <p className="shimmer-loading__para shimmer width-170 mt60 "></p>
                            <p className="shimmer-loading__headpara shimmer  mb20 width-full"></p>
                            <p className="shimmer-loading__para shimmer width-100"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="product-listing__col px-1 product-listing__tile">
                    <div className="lazyload-wrapper ">
                      <div className="product-tile">
                        <div className="product-list-tile__image wow ">
                          <div className="shimmer-loading__image shimmer"></div>
                        </div>
                        <div className="product-tile__details">
                          <div className="width-full">
                            <p className="shimmer-loading__para shimmer width-170 mt60 "></p>
                            <p className="shimmer-loading__headpara shimmer  mb20 width-full"></p>
                            <p className="shimmer-loading__para shimmer width-100"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="product-listing__row">
                  <div className="product-listing__col px-1 product-listing__tile">
                    <div className="lazyload-wrapper ">
                      <div className="product-tile">
                        <div className="product-list-tile__image wow ">
                          <div className="shimmer-loading__image shimmer"></div>
                        </div>
                        <div className="product-tile__details">
                          <div className="width-full">
                            <p className="shimmer-loading__para shimmer width-170 mt60 "></p>
                            <p className="shimmer-loading__headpara shimmer  mb20 width-full"></p>
                            <p className="shimmer-loading__para shimmer width-100"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="product-listing__col px-1 product-listing__tile">
                    <div className="lazyload-wrapper ">
                      <div className="product-tile">
                        <div className="product-list-tile__image wow ">
                          <div className="shimmer-loading__image shimmer"></div>
                        </div>
                        <div className="product-tile__details">
                          <div className="width-full">
                            <p className="shimmer-loading__para shimmer width-170 mt60 "></p>
                            <p className="shimmer-loading__headpara shimmer  mb20 width-full"></p>
                            <p className="shimmer-loading__para shimmer width-100"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="product-listing__col px-1 product-listing__tile">
                    <div className="lazyload-wrapper">
                      <div className="product-tile">
                        <div className="product-list-tile__image wow ">
                          <div className="shimmer-loading__image shimmer"></div>
                        </div>
                        <div className="product-tile__details">
                          <div className="width-full">
                            <p className="shimmer-loading__para shimmer width-170 mt60 "></p>
                            <p className="shimmer-loading__headpara shimmer  mb20 width-full"></p>
                            <p className="shimmer-loading__para shimmer width-100"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PLPLoader
